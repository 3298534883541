<!-- 快乐8尾数走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>快 乐 8 尾 数 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <th colspan="8" class="ranks_b" v-for="(item ,i) in 10" :key='i'>{{item-1}}尾(1-8区)走势</th>

                    </tr>
                    <tr>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>
                        <th class="ranks_c" v-for="(item ,i) in 8" :key='i'>{{item}}</th>

                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item ,x) in klb" :key='x'>
                        <td>{{item.expect}}</td>
                        <!-- 0尾走势图 -->
                        <td class="b-red" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==0 && item2/10==item1?'q-red':'no'">
                                {{ item2%10==0 && item2/10==item1?item1:''}}
                            </div>
                        </td>

                        <!-- 1尾走势图 -->
                        <td class="b-lan" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==1 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==1 &&parseInt(item2/10)==item1-1 ?item1 : ''}}
                            </div>
                        </td>

                        <!-- 2尾走势图 -->
                        <td class="b-red" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==2 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==2 && parseInt(item2/10)==item1-1 ? item1 : ''}}
                            </div>
                        </td>

                        <!-- 3尾走势图 -->
                        <td class="b-lan" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==3 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==3 &&parseInt(item2/10)==item1-1 ?item1 : ''}}
                            </div>
                        </td>

                        <!-- 4尾走势图 -->
                        <td class="b-red" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==4 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==4 && parseInt(item2/10)==item1-1 ? item1 : ''}}
                            </div>
                        </td>

                        <!-- 5尾走势图 -->
                        <td class="b-lan" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==5 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==5 &&parseInt(item2/10)==item1-1 ?item1 : ''}}
                            </div>
                        </td>
                        <!-- 6尾走势图 -->
                        <td class="b-red" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==6 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==6 && parseInt(item2/10)==item1-1 ? item1 : ''}}
                            </div>
                        </td>
                        <!-- 7尾走势图 -->
                        <td class="b-lan" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==7 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==7 &&parseInt(item2/10)==item1-1 ?item1 : ''}}
                            </div>
                        </td>

                        <!-- 8尾走势图 -->
                        <td class="b-red" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==8 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==8 && parseInt(item2/10)==item1-1 ? item1 : ''}}
                            </div>
                        </td>

                        <!-- 9尾走势图 -->
                        <td class="b-lan" v-for="(item1 ,i) in q8" :key='i'>
                            <div v-for="(item2 ,i) in item.red" :key='i'
                                :class="item2%10==9 &&parseInt(item2/10)==item1-1 ? 'q-red' : 'no'">
                                {{ item2%10==9 &&parseInt(item2/10)==item1-1 ?item1 : ''}}
                            </div>
                        </td>

                    </tr>

                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 80" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 80" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 80" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import mitt from '@/js/mitt.js'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                q1: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80],
                q8: [1, 2, 3, 4, 5, 6, 7, 8],

            }
        },
        mounted() {
            this.find()
        },
        computed: {
            klb() {
                return this.$store.state.klb;
            },
        },
        methods: { // 函数体部分，js的主要逻辑控制    
            find() {
                document.documentElement.scrollTop = 0;
                if (this.klb == '') {
                    mitt.emit('klb');
                }
            },
        },
        props: {
        },
       
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a {
        width: 12px;
    }

    .ranks_a1 {
        width: 25px;
    }

    .ranks_c {
        width: 18px;
        height: 18px;
        color: red;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        height: 15px;
        background-color: rgb(191, 224, 224);
    }

    .b-lan {
        height: 15px;
        background-color: rgb(202, 191, 224);
    }

    .q-red {
        height: 13px;
        background-color: rgb(212, 146, 3);
        border-radius: 15px;
        height: 13px;
        color: #fff;
    }

    .q-lan {
        background-color: rgb(60, 117, 52);
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .ssq-lan {
        background-color: rgb(156, 205, 211);
    }

    .no {
        display: none;
    }
</style>